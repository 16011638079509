let consent_actions = (function () {
  const CSC_SERVICE_NAME = 'EWI Existing Customer Cookie';
  const CSC_COOKIE_NAME = 'CSC_AUTHENTICATED';
  function bindEvents() {
    window.addEventListener('ucEvent', function (e) {
      // create "ucEvent" in Usercentrics
      if (e.detail) {
        cscCookieAction(e.detail[CSC_SERVICE_NAME]);
      }
    });
  }

  function setCookie(name, value, expiryDate) {
    document.cookie =
      name +
      '=' +
      value +
      ';expires=' +
      expiryDate +
      ';domain=.e-wie-einfach.de;path=/';
  }

  function cscCookieAction(consentStatus) {
    if (consentStatus === false) {
      let expiryDate = 'Thu, 01 Jan 1970 00:00:01 GMT';
      let cookieValue = 1;
      setCookie(CSC_COOKIE_NAME, cookieValue, expiryDate);
      if (window.location.href.includes('home-bk')) {
        window.location.href = '/';
      }
    }
  }

  function init() {
    bindEvents();
  }

  return {
    init: init,
  };
})();
consent_actions.init();
