(() => {
  window.addEventListener('load', () => {
    const accordionItems = document.querySelectorAll('.accordionItem');

    const handleAccordionItemClick = (accordionItem) => {
      const isOpen = accordionItem.classList.contains('accordionItem--open');
      const accordionContent = accordionItem.querySelector(
        '.accordionItem__content_inner',
      );
      const accordionButton = accordionItem.querySelector('button');

      if (isOpen) {
        accordionItem.classList.remove('accordionItem--open');
        accordionContent.setAttribute('inert', 'true');
        accordionButton.setAttribute('aria-expanded', 'false');
      } else {
        accordionItem.classList.add('accordionItem--open');
        accordionContent.removeAttribute('inert');
        accordionButton.setAttribute('aria-expanded', 'true');
      }
    };

    const setAccesibilityDefaults = (accordionItem) => {
      const isOpen = accordionItem.classList.contains('accordionItem--open');
      const accordionContent = accordionItem.querySelector(
        '.accordionItem__content_inner',
      );
      const accordionButton = accordionItem.querySelector('button');

      if (isOpen) {
        accordionContent.removeAttribute('inert');
        accordionButton.setAttribute('aria-expanded', 'true');
      } else {
        accordionContent.setAttribute('inert', 'true');
        accordionButton.setAttribute('aria-expanded', 'false');
      }
    };

    accordionItems.forEach((accordionItem) => {
      setAccesibilityDefaults(accordionItem);

      const accordionButton = accordionItem.querySelector('button');
      accordionButton.addEventListener('click', () =>
        handleAccordionItemClick(accordionItem),
      );
    });
  });
})();
